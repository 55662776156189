import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'image-with-initials',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
})
export class ImageComponent {
  @Input() backgroundColor: string | undefined;
  @Input() src: string | undefined;
  @Input() alt: string | undefined;
  @Input() initial: string | undefined;
  @Input() initialFontSize: string = '30px';
  @Input() size: string = '70px';
  @Input() textColor: string = '#ffffff';
}
