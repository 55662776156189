<div class="list-empty-state">
  <img src="../../../../assets/no-result.svg" alt="no-results" />
  <p class="label">
    {{
      label
        ? label
        : "The search did not return any results matching the specified criteria."
    }}
  </p>
</div>
