import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { forkJoin, Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { BrandService } from '../../../../shared/services/brand.service';
import { Page } from '../../../../shared/models/page.class';
import { JoinPipe } from '../../../../shared/pipes/join.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SnackBarService } from '../../../../core/services/snackbar.service';
import { FormsModule } from '@angular/forms';
import { BrandLight } from '../../../../shared/models/brand-light.class';
import { PlatformEnum } from '../../../../shared/models/platform.class';
import { MatMenuModule } from '@angular/material/menu';
import { BrandCardComponent } from '../../../../shared/components/cards/brand-card/brand-card.component';
import { SocialCampaignCardComponent } from '../../../../shared/components/cards/social-campaign-card/social-campaign-card.component';
import { PostCardComponent } from '../../../../shared/components/cards/post-card/post-card.component';
import { SocialMediaConnection } from '../../../../shared/models/social-media-connection.class';
import { ListEmptyStateComponent } from '../../../../shared/components/empty-list/empty-list.component';

export interface ExpiryObject {
  brandId: string;
  expiryDatesPerNetwork: {
    [network: string]: string;
  };
}
@Component({
  selector: 'app-brands-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSortModule,
    MatTooltipModule,
    JoinPipe,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatMenuModule,
    BrandCardComponent,
    ListEmptyStateComponent,
  ],
  templateUrl: './brands-table.component.html',
  styleUrl: './brands-table.component.scss',
})
export class BrandsTableComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  public gridView: boolean = true;
  public listView: boolean = false;
  public subscription: Subscription = new Subscription();
  public loading: boolean = false;
  public columns: string[] = [
    'name',
    // 'platform',
    'industry',
    'campaigns',
    'createdOn',
    'updatedOn',
    'actions',
  ];
  public dataSource: MatTableDataSource<BrandLight, MatPaginator> =
    new MatTableDataSource<BrandLight>();
  public filter = {
    page: 0,
    size: 25,
    sortProperty: ['createdOn'],
    sortDirection: 'DESC',
    industries: [''],
    socialNetworks: [''],
  };
  public industries: string[] = [];
  public selectedIndustries: string[] = [];
  public platforms: PlatformEnum[] = Object.values(
    PlatformEnum
  ) as PlatformEnum[];
  public selectedPlatforms: PlatformEnum[] = [];
  public expiryTokenObjects: ExpiryObject[] = [];
  public aboutToExpireTokens: SocialMediaConnection[] = [];
  public expiredTokens: SocialMediaConnection[] = [];
  public hasAnyBrands: boolean = true;

  constructor(
    private brandService: BrandService,
    private router: Router,
    private sbs: SnackBarService
  ) {}

  ngOnInit(): void {
    this.getAnyBrand();
    this.getAllIndustries();
    this.refreshDataFirstPage();
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe((p: PageEvent) => {
      this.filter.size = p.pageSize;
      this.filter.page = p.pageIndex;
      this.refreshData();
    });
  }

  public refreshDataFirstPage(): void {
    this.paginator.pageIndex = 0;
    this.filter.page = 0;
    this.refreshData();
  }

  getAnyBrand() {
    this.brandService.getAll({}).subscribe({
      next: (response) => {
        this.hasAnyBrands = response.totalElements > 0;
      },
    });
  }

  public refreshData(): void {
    this.loading = true;
    forkJoin({
      brands: this.brandService.getAll(this.filter),
      expiry: this.brandService.getTokenExpiryNotificaiton(),
    }).subscribe({
      next: (results) => {
        this.loading = false;
        const { brands, expiry } = results;
        this.paginator.length = brands.totalElements;
        this.dataSource.data = brands.content;
        const scmWithIssues = Array.isArray(expiry) ? expiry : [];
        this.expiryTokenObjects = scmWithIssues;
        this.findExpiredAndAboutToExpireSCM(scmWithIssues);
      },
      error: (error) => {
        this.loading = false;
        this.sbs.error(
          error?.error?.error?.messages[0] ?? 'Error while fetching data'
        );
      },
    });
  }

  public getAllIndustries(): void {
    this.brandService.getAllIndustries().subscribe({
      next: (response: string[]) => {
        this.industries = response;
      },
      error: (error) => {
        this.sbs.error(
          error?.error?.error?.messages[0] ?? 'Error while fetching industries'
        );
      },
    });
  }

  public changeToList(): void {
    this.listView = true;
    this.gridView = false;
  }

  public changeToGrid(): void {
    this.listView = false;
    this.gridView = true;
  }

  public filterByIndustry(): void {
    this.filter.industries =
      this.selectedIndustries.length > 0 ? this.selectedIndustries : [];
    this.refreshDataFirstPage();
  }

  public filterByPlatform(): void {
    this.filter.socialNetworks =
      this.selectedPlatforms.length > 0 ? this.selectedPlatforms : [];
    this.refreshDataFirstPage();
  }

  public navigateToCreateBrand(): void {
    this.router.navigate(['/main-section/main/mad-social/brands/create']);
  }

  public navigateToEditBrand(brandId: string): void {
    this.router.navigate([
      `/main-section/main/mad-social/brands/edit/${brandId}`,
    ]);
  }

  public navigateToCampaigns(brandId: string): void {
    this.router.navigate(
      [`/main-section/main/mad-social/campaigns/campaigns-table`],
      {
        queryParams: {
          brandId,
        },
      }
    );
  }

  private findExpiredAndAboutToExpireSCM(expiryTokenObjects: any[]) {
    let invalidSCM = [];
    let aboutToExpireSCM = [];

    for (let index = 0; index < this.expiryTokenObjects.length; index++) {
      const element = expiryTokenObjects[index];

      if (
        element.healthStatus === 'TOKEN_EXPIRED' ||
        element.healthStatus === 'TOKEN_INVALID'
      ) {
        invalidSCM.push(element);
      }

      if (element.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE') {
        aboutToExpireSCM.push(element);
      }
    }

    this.aboutToExpireTokens = aboutToExpireSCM;
    this.expiredTokens = invalidSCM;
  }

  public getExpiryTooltip(expiry: ExpiryObject): string {
    if (!expiry) return '';
    return Object.entries(expiry.expiryDatesPerNetwork)
      .map(
        ([network, date]) =>
          `${network} token will expire on ${date}, please reconnect. All posts after this date will be unscheduled!\n`
      )
      .join(', ');
  }
}
