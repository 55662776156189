<h1 mat-dialog-title class="text-center">Select {{ data.title }} Page</h1>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-radio-group formControlName="selectedPage">
      <mat-radio-button
        *ngFor="let account of filteredAccounts"
        [value]="
          data.type === 'instagram'
            ? account.instagram_business_account!.id
            : account.id
        "
        style="display: block; margin-bottom: 10px"
      >
        {{ account.name }}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-button
    mat-flat-button
    color="primary"
    [disabled]="!form.get('selectedPage')?.value"
    (click)="onSelect()"
  >
    Select
  </button>
</div>
