<div
  class="brand-card"
  [ngStyle]="{ background: gradient, borderColor: brandStatus() }"
>
  <div class="d-flex align-items-center justify-content-between header-content">
    <div>
      <span class="brand-label">Brand</span>
      <h3 class="m-0 brand-name" (click)="clickCard()">
        <span
          *ngIf="brand.name.length > 25"
          matTooltip="{{ brand.name }}"
          matTooltipPosition="above"
        >
          {{ brand.name | slice : 0 : 25 }}...
        </span>
        <span *ngIf="brand.name.length <= 25">
          {{ brand.name }}
        </span>
        <mat-icon>edit</mat-icon>
      </h3>
    </div>
    <image-with-initials
      [src]="brand.s3LogoCdn"
      [alt]="brand.name"
      [backgroundColor]="brandColor"
      [textColor]="lightColor"
      [initial]="brandNameInitial()"
    ></image-with-initials>
  </div>
  <div
    class="d-flex align-items-center justify-content-between item clickable"
    (click)="clickTitle($event)"
  >
    <p class="mb-2">Social Campaigns</p>
    <p class="mb-2 count">{{ brand.campaignsCount }}</p>
  </div>
  <div class="d-none align-items-center justify-content-between item">
    <p class="mb-2">Mail Campaigns</p>
    <p class="mb-2 count">-</p>
  </div>
  <div class="d-none align-items-center justify-content-between item">
    <p class="mb-2">Landing Pages</p>
    <p class="mb-2 count">-</p>
  </div>
  <div class="d-flex align-items-center justify-content-between item">
    <p class="mb-2">Assets</p>
    <p class="mb-2 count">{{ brand.assetsCount }}</p>
  </div>
  <div class="d-flex align-items-center justify-content-between item mt-5">
    <p class="mb-2"><b>Connections</b></p>
    <p class="mb-2">Status</p>
  </div>
  <div class="d-flex align-items-center justify-content-between item pb-2 mt-2">
    <div class="d-flex align-items-center connection" style="gap: 7px">
      <img
        [src]="
          facebookSocialConnection
            ? '../../../../../assets/facebook.svg'
            : '../../../../../assets/gray-facebook.svg'
        "
        alt="facebook"
      />
      @if( facebookSocialConnection) {
      <span
        class="connection-text"
        [ngClass]="{
          'connection-text-expired':
            facebookSocialConnection &&
            (facebookSocialConnection.healthStatus === 'TOKEN_INVALID' ||
              facebookSocialConnection.healthStatus === 'TOKEN_EXPIRED'),
              'connection-text-about-to-expire':
            facebookSocialConnection &&
            facebookSocialConnection.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE',
        }"
        >{{ facebookSocialConnection.name }}</span
      >
      @if(facebookSocialConnection.url) {
      <a
        [href]="formatLink(facebookSocialConnection.url)"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../../../../../assets/link-icon.svg" alt="link-icon" />
      </a>
      } } @else {
      <span class="connection-text-disabled">Not Connected</span>
      }
    </div>
    <div
      class="dot"
      [ngClass]="{
        'healthy-status':
          facebookSocialConnection &&
          facebookSocialConnection.healthStatus === 'HEALTHY',
        'about-to-expire':
          facebookSocialConnection &&
          facebookSocialConnection.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE',
        'expired-status':
          facebookSocialConnection &&
          (facebookSocialConnection.healthStatus === 'TOKEN_INVALID' ||
            facebookSocialConnection.healthStatus === 'TOKEN_EXPIRED')
      }"
    ></div>
  </div>
  <div class="d-flex align-items-center justify-content-between item pb-2 mt-2">
    <div class="d-flex align-items-center" style="gap: 7px">
      <img
        [src]="
          linkedinSocialConnection
            ? '../../../../../assets/linkedin.svg'
            : '../../../../../assets/gray-linkedin.svg'
        "
        alt="linkedin"
      />
      @if( linkedinSocialConnection) {
      <span
        class="connection-text"
        [ngClass]="{
          'connection-text-expired':
            linkedinSocialConnection &&
            (linkedinSocialConnection.healthStatus === 'TOKEN_INVALID' ||
              linkedinSocialConnection.healthStatus === 'TOKEN_EXPIRED'),
              'connection-text-about-to-expire':
            linkedinSocialConnection &&
            linkedinSocialConnection.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE',
        }"
        >{{ linkedinSocialConnection.name }}</span
      >
      @if(linkedinSocialConnection.url) {
      <a
        [href]="formatLink(linkedinSocialConnection.url)"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../../../../../assets/link-icon.svg" alt="link-icon" />
      </a>
      } } @else {
      <span class="connection-text-disabled">Not Connected</span>
      }
    </div>
    <div
      class="dot"
      [ngClass]="{
        'healthy-status':
          linkedinSocialConnection &&
          linkedinSocialConnection.healthStatus === 'HEALTHY',
        'about-to-expire':
          linkedinSocialConnection &&
          linkedinSocialConnection.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE',
        'expired-status':
          linkedinSocialConnection &&
          (linkedinSocialConnection.healthStatus === 'TOKEN_INVALID' ||
            linkedinSocialConnection.healthStatus === 'TOKEN_EXPIRED')
      }"
    ></div>
  </div>
  <div class="d-flex align-items-center justify-content-between item pb-2 mt-2">
    <div class="d-flex align-items-center" style="gap: 7px">
      <img
        [src]="
          instagramSocialConnection
            ? '../../../../../assets/instagram.svg'
            : '../../../../../assets/gray-instagram.svg'
        "
        alt="instagram"
      />
      @if( instagramSocialConnection) {
      <span
        class="connection-text"
        [ngClass]="{
          'connection-text-expired':
            instagramSocialConnection &&
            (instagramSocialConnection.healthStatus === 'TOKEN_INVALID' ||
              instagramSocialConnection.healthStatus === 'TOKEN_EXPIRED'),
              'connection-text-about-to-expire':
            instagramSocialConnection &&
            instagramSocialConnection.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE',
        }"
        >{{ instagramSocialConnection.name }}</span
      >
      @if(instagramSocialConnection.url) {
      <a
        [href]="formatLink(instagramSocialConnection.url)"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../../../../../assets/link-icon.svg" alt="link-icon" />
      </a>
      } } @else {
      <span class="connection-text-disabled">Not Connected</span>
      }
    </div>
    <div
      class="dot"
      [ngClass]="{
        'healthy-status':
          instagramSocialConnection &&
          instagramSocialConnection.healthStatus === 'HEALTHY',
        'about-to-expire':
          instagramSocialConnection &&
          instagramSocialConnection.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE',
        'expired-status':
          instagramSocialConnection &&
          (instagramSocialConnection.healthStatus === 'TOKEN_INVALID' ||
            instagramSocialConnection.healthStatus === 'TOKEN_EXPIRED')
      }"
    ></div>
  </div>
  <div class="d-flex align-items-center justify-content-between tags mt-5">
    <p class="m-0 tag-label">Industry</p>
    <!-- <p class="mb-0 count" style="height: 23px">-</p> -->
    <div class="d-flex align-items-center justify-content-end" style="gap: 5px">
      <div class="tag" title="{{ brand.industry }}">{{ brand.industry }}</div>
    </div>
  </div>
</div>
