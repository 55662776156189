<div class="container pb-4">
  <div class="spinner-container" [hidden]="!loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row d-flex justify-content-center pt-5 mb-2">
    <div class="col-md-12 d-flex align-items-center">
      <h1>
        @if (allowBack) {
        <button
          mat-icon-button
          color="primary"
          aria-label="Go back"
          (click)="goBack()"
        >
          <i class="fa-solid fa-arrow-left icon-back"></i>
        </button>

        }
        {{ editMode ? "Edit brand" : "Create a brand" }}
      </h1>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="form-header border-grey">
        <h3 class="title">Brand details</h3>
        <button class="arrow-container" (click)="toggleBrandDetailsForm()">
          <img
            src="../../../../../assets/thin-arrow.svg"
            alt="arrow"
            class="arrow"
            [ngClass]="{ 'arrow-up': showBrandDetailsForm }"
          />
        </button>
      </div>
      <div
        class="brand-form-wrapper bg-white"
        [ngClass]="{ 'close-form': !showBrandDetailsForm }"
      >
        <form [formGroup]="brandForm" class="brand-form">
          <div class="row">
            <div class="col-md-6" style="min-width: 550px; margin-left: 25%">
              <div class="d-flex justify-content-between">
                <div class="col-7">
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-2">Name *</label>
                      <mat-form-field appearance="outline" class="w-100">
                        <input
                          matInput
                          placeholder="Name"
                          formControlName="name"
                          maxlength="200"
                        />
                        <mat-error
                          *ngIf="
                            brandForm.controls['name'].invalid &&
                            brandForm.controls['name'].hasError('required')
                          "
                        >
                          Please enter name
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-2">Industry *</label>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="industry">
                          @for(industry of industries; track industry){
                          <mat-option [value]="industry">{{
                            industry
                          }}</mat-option>
                          }
                        </mat-select>
                        <mat-error
                          *ngIf="
                            brandForm.controls['industry'].invalid &&
                            brandForm.controls['industry'].hasError('required')
                          "
                        >
                          Please select industry
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="col-12" style="height: 162px; position: relative">
                    <label class="mb-2">Brand / Product image</label>
                    @if(logo) {
                    <div class="image-container">
                      <img [src]="logo" alt="brand-logo" class="brand-logo" />
                      <div class="overlay"></div>
                      <div class="image-actions">
                        <button
                          class="action action-left"
                          (click)="imageUpload.click()"
                        >
                          Change
                        </button>
                        <button
                          class="action action-right"
                          (click)="deleteBrandLogo()"
                        >
                          Remove
                        </button>
                        <input
                          type="file"
                          class="form-control mt-2"
                          (change)="onFileSelected($event)"
                          #imageUpload
                          hidden
                          accept="image/*"
                        />
                      </div>
                    </div>
                    } @else {
                    <div
                      class="upload-image-placeholder"
                      (click)="imageUpload.click()"
                    >
                      <img
                        src="../../../../../assets/image-upload.svg"
                        alt="image-upload"
                      />
                      <span class="placeholder-label">Upload image</span>
                    </div>
                    <input
                      type="file"
                      class="form-control mt-2"
                      (change)="onFileSelected($event)"
                      #imageUpload
                      hidden
                      accept="image/*"
                    />

                    }
                  </div>
                </div>
              </div>

              <div class="row pb-4">
                <div class="col-12">
                  <label class="mb-2">Details</label>
                  <mat-form-field appearance="outline" class="w-100">
                    <textarea
                      matInput
                      placeholder="Tell us more about your Brand"
                      rows="4"
                      formControlName="details"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-12 text-center mt-2">
                <button
                  mat-flat-button
                  color="primary"
                  (click)="submit()"
                  [disabled]="!brandForm.valid"
                >
                  {{ brandId ? "Update" : "Create Brand" }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="form-header border-grey">
        <h3 class="title" [ngStyle]="{ color: !brandId && '#b1b1b5' }">
          Connect social accounts
        </h3>
        <button
          class="arrow-container"
          [disabled]="!brandId"
          (click)="toggleConnectionsForm()"
        >
          <img
            src="../../../../../assets/thin-arrow.svg"
            alt="arrow"
            class="arrow"
            [ngClass]="{ 'arrow-up': showConnectionsForm }"
          />
        </button>
      </div>
      <div
        class="connection-form-wrapper bg-white"
        [ngClass]="{ 'close-form': !showConnectionsForm }"
      >
        <div class="col-md-9 connection-container py-5">
          <!-- Connected social accounts -->
          <div
            class="list-label"
            *ngIf="socialConnectionsDataSource.data.length > 0"
          >
            <p class="mb-2"><b> Connected accounts</b></p>
          </div>
          <!-- Table -->
          <table
            *ngIf="socialConnectionsDataSource.data.length > 0"
            mat-table
            [dataSource]="socialConnectionsDataSource.data"
            class="mat-elevation-z8 scm-table"
          >
            <!-- account -->
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef class="flex-grow-name">
                Account
              </th>
              <td mat-cell *matCellDef="let element" class="flex-grow-name">
                <div
                  class="d-flex align-items-center connection"
                  style="gap: 7px"
                >
                  <img
                    [src]="getImageSrc(element.socialNetwork)"
                    alt="facebook"
                  />
                  <span class="connection-text">{{ element.name }}</span>
                  @if(element.url) {
                  <a
                    [href]="formatLink(element.url)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="../../../../../assets/link-icon.svg"
                      alt="link-icon"
                    />
                  </a>
                  }
                </div>
              </td>
            </ng-container>

            <!-- Health -->
            <ng-container matColumnDef="healthStatus">
              <th mat-header-cell *matHeaderCellDef class="health-width">
                Health
              </th>
              <td mat-cell *matCellDef="let element" class="health-width">
                <span
                  class="health"
                  [ngClass]="{
                    'token-expired':
                      element.healthStatus === 'TOKEN_EXPIRED' ||
                      element.healthStatus === 'TOKEN_INVALID',
                    'token-about-to-expire':
                      element.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE'
                  }"
                >
                  {{ connectionHealthStatus(element) }}
                </span>
              </td>
            </ng-container>

            <!-- Token expiration -->
            <ng-container matColumnDef="tokenExpiryDate">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="token-expiration-width"
              >
                Token expiry
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="token-expiration-width"
                [ngClass]="{
                  expired:
                    element.healthStatus === 'TOKEN_EXPIRED' ||
                    element.healthStatus === 'TOKEN_INVALID',
                  'about-to-expire':
                    element.healthStatus === 'TOKEN_ABOUT_TO_EXPIRE'
                }"
              >
                <span>{{ element.tokenExpiryDate | date : "shortDate" }}</span>
              </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="custom-width-actions text-end"
              ></th>
              <td
                mat-cell
                *matCellDef="let element"
                class="custom-width-actions text-end"
              >
                <button
                  mat-icon-button
                  disableRipple="true"
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="menu"
                >
                  <i class="fa-regular fa-ellipsis-vertical fa-2xs"></i>
                </button>
                <mat-menu xPosition="before" #menu="matMenu">
                  <button mat-menu-item (click)="reconnectConnection(element)">
                    <i class="fa-solid fa-plug"></i>
                    Reconnect
                  </button>
                  <button
                    mat-menu-item
                    (click)="openDeleteConnectionDialog(element)"
                  >
                    <i class="fa-solid fa-xmark mx-1"></i>
                    Delete
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns"></tr>
          </table>
          <!-- Connect social network -->
          <div
            class="list-label mb-4"
            *ngIf="
              socialConnectionsDataSource.data.length < 3 &&
              socialConnectionsDataSource.data.length > 0
            "
          >
            <p class="mb-2"><b> Connect other accounts</b></p>
          </div>
          <div class="row" *ngIf="socialConnectionsDataSource.data.length < 3">
            <div class="d-flex" style="gap: 10px">
              <div
                class="social-media-card"
                *ngIf="!findSocialMedialConnection('FACEBOOK')"
                (click)="handleIconClick('FB')"
              >
                <img
                  src="../../../../../assets/facebook.svg"
                  alt="Facebook"
                  style="width: 32px; height: 32px"
                />
                <span class="label">Connect facebook</span>
              </div>
              <div
                class="social-media-card"
                *ngIf="!findSocialMedialConnection('INSTAGRAM')"
                (click)="handleIconClick('IG')"
              >
                <img
                  src="../../../../../assets/instagram.svg"
                  alt="Facebook"
                  style="width: 32px; height: 32px"
                />
                <span class="label">Connect instagram</span>
              </div>
              <div
                class="social-media-card"
                *ngIf="!findSocialMedialConnection('LINKEDIN')"
                (click)="handleIconClick('LI')"
              >
                <img
                  src="../../../../../assets/linkedin.svg"
                  alt="Facebook"
                  style="width: 32px; height: 32px"
                />
                <span class="label">Connect linkedin</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-header border-grey">
        <h3 class="title" [ngStyle]="{ color: !brandId && '#b1b1b5' }">
          Brand voice training
          <img
            src="../../../../../assets/beta.svg"
            alt="beta"
            [ngClass]="!brandId ? 'opacity-50' : ''"
          />
        </h3>
        <button
          class="arrow-container"
          [disabled]="!brandId"
          (click)="toggleVoiceForm()"
        >
          <img
            src="../../../../../assets/thin-arrow.svg"
            alt="arrow"
            class="arrow"
            [ngClass]="{ 'arrow-up': showVoiceForm }"
          />
        </button>
      </div>
      <div
        class="voice-form-wrapper bg-white"
        [ngClass]="{ 'close-form': !showVoiceForm }"
        [formGroup]="brandForm"
      >
        <div class="row d-flex">
          <div class="col-6 voice-container fw-semibold mt-auto">
            <div class="row">
              <div class="col-2">
                <span>Enable</span>
              </div>
              <div class="col-6">
                <mat-slide-toggle color="primary" formControlName="brandVoiceEnabled" (change)="updateBrand(true)"></mat-slide-toggle>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-2">
                <span>Status</span>
              </div>
              <div class="col-6 d-flex align-items-center">
                <div class="dot" [ngClass]="brandVoiceStatusColorMap[getBrandVoiceStatus()] + '-status'"></div>
                <span class="fw-light">{{brandVoiceStatusLabels[getBrandVoiceStatus()]}}</span>
              </div>
            </div>
            <div class="row">
              <span class="description mt-4 fw-light fs-5">
                by enabling Brand voice training option you will be receiving additional variation based on your brand style and tone. Don’t worry this will not affect in any way your current posts.
              </span>
            </div>
          </div>
          <div class="col-6 voice-image-container py-5">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
