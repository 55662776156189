import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  standalone: true
})
export class JoinPipe implements PipeTransform {

  transform(value: any[], property?: string, separator: string = ', '): string {
    if (!value) return '';
    if (property) {
      return value.map(item => this.capitalizeFirstLetter(item[property])).join(separator);
    }
    return value.map(item => this.capitalizeFirstLetter(item)).join(separator);
  }

  private capitalizeFirstLetter(word: string): string {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
}
