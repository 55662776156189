import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';

interface Account {
    id: string;
    name: string;
    picture: {
      data: {
        height: number;
        is_silhouette: boolean;
        url: string;
        width: number;
      }
    };
    instagram_business_account?: {
      id: string;
    };
    access_token: string;
  }

@Component({
  selector: 'app-facebook-page-select-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  templateUrl: './facebook-page-select-dialog.component.html',
})

export class FacebookPageSelectDialogComponent {
  form: FormGroup;
  filteredAccounts: Account[] = [];

  constructor(
    private dialogRef: MatDialogRef<FacebookPageSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      selectedPage: [null]
    });

    this.filteredAccounts = this.data.accounts;

    if(data.type == 'instagram') {
        this.filteredAccounts = this.data.accounts.filter((account: { instagram_business_account: any; }) => account.instagram_business_account);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect(): void {
    this.dialogRef.close(this.form.value.selectedPage);
  }
}