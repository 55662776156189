<div
  class="image-container"
  style="--size:{{ size }}; --backgroundColor:{{ backgroundColor }}"
>
  @if(src){
  <img [src]="src" [alt]="alt" class="image" />
  } @else if (initial) {
  <span class="initial" style="--initialSize:{{ initialFontSize }}; color:{{textColor}}">{{
    initial
  }}</span>
  }
</div>
