import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'list-empty-state',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './empty-list.component.html',
  styleUrl: './empty-list.component.scss',
})
export class ListEmptyStateComponent {
  @Input() label: string | undefined;
}
