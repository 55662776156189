export class StringUtils {
    public static uuidToHexColor(uuid: string): string {
        let hash = 0;
        for (let i = 0; i < uuid.length; i++) {
            const char = uuid.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
    
        const min = 0x99;
        const max = 0xcc;
        const r = ((hash >> 16) & 0x33) + min;
        const g = ((hash >> 8) & 0x33) + min; 
        const b = (hash & 0x33) + min; 
    
        const hexColor = '#' + 
                         r.toString(16).padStart(2, '0') +
                         g.toString(16).padStart(2, '0') +
                         b.toString(16).padStart(2, '0');
    
        return hexColor;
    }
    
    public static adjustBrightness(hexColor: string, amount: number): string {
        let usePound = false;
    
        if (hexColor[0] === "#") {
            hexColor = hexColor.slice(1);
            usePound = true;
        }
    
        const num = parseInt(hexColor, 16);
    
        let r = (num >> 16) + amount;
        if (r > 255) r = 255;
        else if (r < 0) r = 0;
    
        let g = ((num >> 8) & 0x00FF) + amount;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
    
        let b = (num & 0x0000FF) + amount;
        if (b > 255) b = 255;
        else if (b < 0) b = 0;
    
        return (usePound ? "#" : "") + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
    }
}