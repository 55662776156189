<div class="confirm-dialog-header">
  <h1 class="m-0 px-0 title">Confirmation dialog</h1>
  <button class="close-button" (click)="dialogRef.close(false)">
    <i class="fal fa-remove"></i>
  </button>
</div>
<mat-dialog-content class="confirm-dialog-content">
  <p class="text-center message">{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions class="confirm-dialog-actions">
  <button mat-flat-button (click)="dialogRef.close(false)" class="btn-action">
    Cancel
  </button>
  <button
    mat-flat-button
    (click)="dialogRef.close(true)"
    color="primary"
    class="btn-action"
  >
    Confirm
  </button>
</mat-dialog-actions>
